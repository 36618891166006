import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Nav, Tab } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-rangeslider/lib/index.css";
import Pagination from "@mui/material/Pagination";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import axios from "axios";

import Spinner from "jsx/components/Spinner";
import Footer from "jsx/pages/layout/footer";
import Header from "jsx/pages/layout/header";
import Sidebar from "jsx/pages/layout/sidebar";
import { raoToTao, shortenAddress } from "jsx/utils/validator";
import SettingsSubmenu from "jsx/pages/layout/submenu_settings";
import { toast, ToastContainer } from "react-toastify";
import { dark } from "@mui/material/styles/createPalette";
import useAuth from "../../../context/useAuth";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#131722",
  border: "1px solid #303030",
  boxShadow: 24,
  p: 4,
};

function Naming() {
  const navigate = useNavigate();

  const { isAuthenticated , user } = useAuth();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/signin");
      return;
    }
  }, []);


  const [loading, setLoading] = useState(false);
  const [fadeSpinner, setFadeSpinner] = useState(false); // Controls spinner fade

  const [totalUsers, setTotalNames] = useState(0);
  const [searchNameOrAddress, setSearchNameOrAddress] = useState("");
  const [names, setNames] = useState([]);

  const [pageSize, setPageSize] = useState("50");
  const [userPage, setUserPage] = useState(0);
  const [currentUserPage, setCurrentUserPage] = useState(1);

  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);

  const [selectedName, setSelectedName] = useState({});

  const [editName, setEditName] = useState("");
  const [editAddress, setEditAddress] = useState("");
  const [editUserPassword, setEditUserPassword] = useState("");
  const [editUserRole, setEditUserRole] = useState("");
  const [editUserVerified, setEditUserVerified] = useState("");

  const [addName, setAddName] = useState("");
  const [addAddress, setAddress] = useState("");

  useEffect(() => {
    clickSearch();
  }, [currentUserPage]);

  const clickSearch = async () => {
    try {
      setLoading(true);
      const token = user?.token;

      var response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/naming`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            search: searchNameOrAddress,
            page_num: currentUserPage,
            page_size: pageSize,
          },
        }
      );
      console.log(response);
      setNames(response.data.data);
      setTotalNames(response.data.total);
      setUserPage(
        Math.floor(Number(response.data.total) / Number(pageSize)) + 1
      );

      setLoading(false);
    } catch (ex) {
      if (ex.response && ex.response.status == 403) {
        navigate("/signin");
        return;
      }
      console.error(ex);
      setLoading(false);
    }
  };

  const changeUserPage = (e) => {
    setCurrentUserPage(e.target.value);
  };

  const handleCloseDeleteUserModal = () => setOpenDelete(false);
  const handleCloseEditUserModal = () => setOpenEdit(false);
  const handleCloseAddNameModal = () => setOpenAdd(false);

  const handleEditUser = (user) => {
    setOpenEdit(true);
    setSelectedName(user);

    setEditName(user.name);
    setEditAddress(user.address);
  };
  const handleDeleteUser = (user) => {
    setOpenDelete(true);
    setSelectedName(user);
  };

  const handleConfirmDeleteUser = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      if (token == null) {
        navigate("/signin");
        setLoading(false);
        return;
      }

      var response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/naming`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            address: selectedName.address,
          },
        }
      );
      console.log(response);
      await clickSearch();
      setOpenDelete(false);
      setLoading(false);

      toast.success(`${selectedName.email} was removed successfully`, {
        theme: "dark",
      });
    } catch (ex) {
      if (ex.response && ex.response.status == 403) {
        navigate("/signin");
        return;
      }

      setLoading(false);
      toast.error(`${selectedName.email} was not removed`, {
        theme: "dark",
      });
    }
  };

  const handleConfirmEditUser = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      if (token == null) {
        navigate("/signin");
        setLoading(false);
        return;
      }

      const data = {
        address: selectedName.address,
        name: editName,
      };

      var response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/naming`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      await clickSearch();
      setOpenEdit(false);
      setLoading(false);

      toast.success(`${selectedName.email} was updated successfully`, {
        theme: "dark",
      });
    } catch (ex) {
      if (ex.response && ex.response.status == 403) {
        navigate("/signin");
        return;
      }

      setLoading(false);
      toast.error(`${selectedName.email} was not updated`, {
        theme: "dark",
      });
    }
  };

  const handleAddName = () => {
    setAddName("");
    setAddress("");
    setOpenAdd(true);
  };

  const handleConfirmAddName = async () => {
    if (!addAddress) {
      toast.warn("Please input address", { theme: dark });
      return;
    }
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      if (token == null) {
        navigate("/signin");
        setLoading(false);
        return;
      }

      const data = {
        name: addName,
        address: addAddress,
      };

      var response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/naming`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response);

      if (response.data == true) {
        await clickSearch();
        setOpenAdd(false);
        setLoading(false);

        toast.success(`${addName} was created successfully`, {
          theme: "dark",
        });
      } else {
        setLoading(false);
        setOpenAdd(false);
        toast.error(
          response.data.msg
            ? response.data.msg
            : "The address or name was added already.",
          {
            theme: "dark",
          }
        );
      }
    } catch (ex) {
      if (ex.response && ex.response.status == 403) {
        navigate("/signin");
        return;
      }

      setLoading(false);
      toast.error(`${addName} was not updated`, {
        theme: "dark",
      });
    }
  };

  return (
    <>
      {loading && <Spinner isVisible={fadeSpinner} />}
      <Header />
      <Sidebar />
      <div className="content-body">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="card sub-menu">
                <div className="card-body">
                  <SettingsSubmenu />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-12">
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Naming Address</h4>
                  </div>
                  <div className="card-body">
                    <div className="transaction-table">
                      <div className="table-responsive">
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col">
                              There are{" "}
                              {
                                <b style={{ color: "greenyellow" }}>
                                  {totalUsers}
                                </b>
                              }{" "}
                              named addresses
                            </div>
                          </div>
                          <div className="row filter">
                            <div className="col-xl-12">
                              <div className="row">
                                <div className="mb-3 col-xl-4">
                                  <span className="fs-11">Name or Address</span>
                                  <input
                                    type="string"
                                    className="form-control"
                                    placeholder="Name or Address"
                                    value={searchNameOrAddress}
                                    onChange={(e) =>
                                      setSearchNameOrAddress(e.target.value)
                                    }
                                  />
                                </div>

                                <div className="mb-3 col-xl-2">
                                  <span className="fs-11">Page Size</span>
                                  <select
                                    className="form-control"
                                    name="country"
                                    value={pageSize}
                                    onChange={(e) =>
                                      setPageSize(e.target.value)
                                    }
                                  >
                                    <option value="20">PageSize - 20</option>
                                    <option value="50">PageSize - 50</option>
                                    <option value="100">PageSize - 100</option>
                                  </select>
                                </div>

                                <div className="col-md-auto offset-md-2 text-end">
                                  <button
                                    className="btn btn-dark waves-effect small mt-4"
                                    onClick={clickSearch}
                                  >
                                    <i className="mdi mdi-account-search"></i>
                                    <span> Search</span>
                                  </button>
                                  <button className="btn btn-dark waves-effect mt-4">
                                    <i className="mdi mdi-file-export"></i>
                                    <span> Export CSV</span>
                                  </button>
                                  <button
                                    className="btn btn-dark waves-effect small mt-4"
                                    onClick={handleAddName}
                                  >
                                    <i className="mdi mdi-account-plus"></i>
                                    <span> Add</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-2 mb-2">
                            <div className="col-md-12 justify-content-sm-center">
                              <Pagination
                                count={userPage}
                                color="primary"
                                onChange={changeUserPage}
                              />
                            </div>
                          </div>
                          <table className="table table-striped mb-0 table-responsive-sm">
                            <thead>
                              <tr>
                                <th className="text-center">No</th>
                                <th className="text-center">Name</th>
                                <th className="text-center">Address</th>
                                <th className="text-center">User Email</th>
                                <th className="text-center">Created At</th>
                                <th className="text-center">Updated At</th>
                                <th className="text-center">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {names.map((name, index) => {
                                return (
                                  <tr>
                                    <td className="text-center">{index + 1}</td>
                                    <td className="text-center">{name.name}</td>
                                    <td className="text-center">
                                      {shortenAddress(name.address)}
                                    </td>
                                    <td className="text-center">
                                      {name.user_email}
                                    </td>
                                    <td className="text-center">
                                      {new Date(
                                        name.created_at
                                      ).toLocaleDateString() +
                                        " " +
                                        new Date(
                                          name.created_at
                                        ).toLocaleTimeString()}
                                    </td>
                                    <td className="text-center">
                                      {new Date(
                                        name.updated_at
                                      ).toLocaleDateString() +
                                        " " +
                                        new Date(
                                          name.updated_at
                                        ).toLocaleTimeString()}
                                    </td>
                                    <td className="text-center">
                                      {" "}
                                      <Link
                                        className="googlePlus text-center"
                                        to={"#"}
                                        onClick={() => handleEditUser(name)}
                                      >
                                        <span>
                                          <i className="mdi mdi-account-edit"></i>{" "}
                                          Edit
                                        </span>
                                      </Link>
                                      {" | "}
                                      <Link
                                        className="googlePlus text-center"
                                        to={"#"}
                                        onClick={(e) => handleDeleteUser(name)}
                                      >
                                        <span>
                                          <i className="mdi mdi-delete"></i>{" "}
                                          Delete
                                        </span>
                                      </Link>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      <Modal
        open={openDelete}
        onClose={handleCloseDeleteUserModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Delete
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure to delete this account?
          </Typography>
          <div className="text-center mt-4">
            <Button
              color="error"
              variant="outlined"
              size="small"
              onClick={handleConfirmDeleteUser}
            >
              Yes
            </Button>
            <Button
              className="ms-3"
              variant="outlined"
              size="small"
              onClick={(e) => setOpenDelete(false)}
            >
              No
            </Button>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openEdit}
        onClose={handleCloseEditUserModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Edit User
          </Typography>

          <div className="row mt-3 mb-2">
            <div className="col-4">Name</div>
            <div className="col-8">
              <input
                type="string"
                className="form-control"
                placeholder="Name"
                value={editName}
                onChange={(e) => setEditName(e.target.value)}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-4">Address</div>
            <div className="col-8">
              <input
                type="string"
                className="form-control"
                placeholder="Address"
                readOnly={true}
                value={editAddress}
              />
            </div>
          </div>

          <div className="text-center mt-4">
            <Button
              color="error"
              variant="outlined"
              size="small"
              onClick={handleConfirmEditUser}
            >
              Yes
            </Button>
            <Button
              className="ms-3"
              variant="outlined"
              size="small"
              onClick={(e) => setOpenEdit(false)}
            >
              No
            </Button>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openAdd}
        onClose={handleCloseAddNameModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add Name for Address
          </Typography>

          <div className="row mt-3 mb-2">
            <div className="col-4">Name</div>
            <div className="col-8">
              <input
                type="string"
                className="form-control"
                placeholder="Name"
                value={addName}
                onChange={(e) => setAddName(e.target.value)}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-4">Address</div>
            <div className="col-8">
              <input
                type="string"
                className="form-control"
                placeholder="Address"
                value={addAddress}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
          </div>

          <div className="text-center mt-4">
            <Button
              color="error"
              variant="outlined"
              size="small"
              onClick={handleConfirmAddName}
            >
              Yes
            </Button>
            <Button
              className="ms-3"
              variant="outlined"
              size="small"
              onClick={(e) => setOpenAdd(false)}
            >
              No
            </Button>
          </div>
        </Box>
      </Modal>
      <ToastContainer />
    </>
  );
}

export default Naming;
