import { useState, useEffect } from "react";
import AuthContext from "./AuthContext";

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const storedUser = localStorage.getItem("user"); // Check localStorage for a stored user
    if (storedUser) {
      setUser(JSON.parse(storedUser)); // If a user is found in localStorage, set the user state
      setIsAuthenticated(true);
    }
  }, []);

  const login = (user) => {
    // Replace with real authentication logic (e.g., API call)
    setUser(user);
    setIsAuthenticated(true);
    localStorage.setItem("user", JSON.stringify(user)); // Save user in localStorage for persistence
  };

  // Logout function
  const logout = () => {
    setUser(null);
    setIsAuthenticated(false);
    localStorage.removeItem("user"); // Clear the user from localStorage
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
