import React from "react";
import { Link } from "react-router-dom";

function SettingsSubmenu() {
  return (
    <>
      <ul className="d-flex">
        <li className="nav-item">
          <Link
            to={"/settings"}
            className={
              window.location.pathname == "/settings"
                ? "active nav-link"
                : "nav-link"
            }
          >
            <i className="mdi mdi-account"></i>
            <span>Users</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to={"/settings/log"}
            className={
              window.location.pathname == "/settings/log"
                ? "active nav-link"
                : "nav-link"
            }
          >
            <i className="mdi mdi-buffer"></i>
            <span>Log</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to={"/settings/naming"}
            className={
              window.location.pathname == "/settings/naming"
                ? "active nav-link"
                : "nav-link"
            }
          >
            <i className="mdi mdi-rename-box"></i>
            <span>Naming</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to={"/settings/filter"}
            className={
              window.location.pathname == "/settings/filter"
                ? "active nav-link"
                : "nav-link"
            }
          >
            <i className="mdi mdi-filter"></i>
            <span>Filter Address</span>
          </Link>
        </li>
      </ul>
    </>
  );
}

export default SettingsSubmenu;
