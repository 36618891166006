import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Nav, Tab } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-rangeslider/lib/index.css";
import Pagination from "@mui/material/Pagination";

import axios from "axios";
import Footer from "jsx/pages/layout/footer";
import Header from "jsx/pages/layout/header";
import Sidebar from "jsx/pages/layout/sidebar";
import Spinner from "jsx/components/Spinner";
import HotkeySubmenu from "jsx/pages/layout/submenu_hotkey";

import { raoToTao, shortenAddress } from "jsx/utils/validator";
import TimeDatePicker from "jsx/components/datepicker";
import { toast, ToastContainer } from "react-toastify";
import { convertToCSV } from "jsx/utils/utils";
import useAuth from "../../../context/useAuth";

function TaoTxns() {
  const navigate = useNavigate();

  const { isAuthenticated, user } = useAuth();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/signin");
      return;
    }
  }, []);

  const [loadingComment, setLoadingComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [fadeSpinner, setFadeSpinner] = useState(false); // Controls spinner fade

  const [totalTxns, setTotalTxns] = useState(0);
  const [currentTxPage, setCurrentTxPage] = useState(1);
  const [txns, setTxns] = useState([]);
  const [txnPages, setTxnPages] = useState(0);

  // filter states
  const [searchAddr, setSearchAddr] = useState("");
  const [searchFromAddress, setSearchFromAddress] = useState(0);
  const [searchToAddress, setSearchToAddress] = useState(0);
  const [searchTxHash, setSearchTxHash] = useState(0);
  const [searchMinAmount, setSearchMinAmount] = useState(0);
  const [searchMaxAmount, setSearchMaxAmount] = useState(0);
  const [searchBlockNumber, setSearchBlockNumber] = useState(0);
  const [searchBlockStart, setSearchBlockStart] = useState(0);
  const [searchBlockEnd, setSearchBlockEnd] = useState(0);
  const [searchStartDate, setSearchStartDate] = useState();
  const [searchEndDate, setSearchEndDate] = useState();
  const [order, setOrder] = useState("timestamp_desc");
  const [pageSize, setPageSize] = useState("50");

  useEffect(() => {
    clickSearch();
  }, [currentTxPage]);

  const changeTxPage = (event, value) => {
    setCurrentTxPage(value);
  };

  const clickSearch = async () => {
    try {
      setLoading(true);
      const token = user?.token;

      var response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/account/txn`,
        {
          addr: searchAddr,
          from: searchFromAddress,
          to: searchToAddress,
          tx_hash: searchTxHash,
          amount_min: searchMinAmount * 1000000000,
          amount_max: searchMaxAmount * 1000000000,
          block_num: searchBlockNumber,
          order: order,
          from_block_num: searchBlockStart,
          to_block_num: searchBlockEnd,
          from_date:
            (new Date(searchStartDate).getTime() -
              new Date("1970-01-01").getTime()) /
            1000,
          end_date:
            (new Date(searchEndDate).getTime() -
              new Date("1970-01-01").getTime()) /
            1000,
          pageNum: currentTxPage,
          pageSize: pageSize,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      setTxns(response.data.data);
      setTotalTxns(response.data.pagination.total_items);
      setTxnPages(response.data.pagination.total_pages);
      setLoading(false);
    } catch (ex) {
      if (ex.response && ex.response.status == 403) {
        navigate("/signin");
        return;
      }
      console.error(ex);
      setLoading(false);
    }
  };

  const copyToClipboard = (textToCopy) => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        toast.success(`Copied the text!`, { theme: "dark" });
      })
      .catch((err) => {
        toast.error(`Failed to copy`, { theme: "dark" });
      });
  };

  const handleDownloadCSV = async () => {
    try {
      setLoading(true);
      const token = user?.token;

      var formatedDatas = [];
      var gindex = 0;

      // for (var i = 1; i < totalHotkeys / 100; i++) {
      var itPages = txnPages < 10 ? txnPages : 10;
      for (var i = 1; i <= itPages; i++) {
        var response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/account/txn`,
          {
            addr: searchAddr,
            from: searchFromAddress,
            to: searchToAddress,
            tx_hash: searchTxHash,
            amount_min: searchMinAmount,
            amount_max: searchMaxAmount,
            block_num: searchBlockNumber,
            order: order,
            from_block_num: searchBlockStart,
            to_block_num: searchBlockEnd,
            pageNum: i,
            pageSize: 100,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setLoadingComment(`Getting ${i} page`);

        const unformatedData = response.data.data;

        unformatedData.forEach((txn, index) => {
          var formatedData = {
            No: gindex,
            From: txn.from.ss58,
            To: txn.to.ss58,
            Amount: raoToTao(txn.amount),
            Fee: raoToTao(txn.fee),
            Date:
              new Date(txn.timestamp).toLocaleDateString() +
              " " +
              new Date(txn.timestamp).toLocaleTimeString(),
            TxHash: txn.transaction_hash,
          };
          gindex++;
          formatedDatas.push(formatedData);
        });
      }

      setLoadingComment(``);

      const csvData = convertToCSV(formatedDatas); // Convert data to CSV
      const blob = new Blob([csvData], { type: "text/csv" }); // Create a blob from CSV
      const url = window.URL.createObjectURL(blob); // Create a download link from blob

      // Create a link element, set the download attribute, and trigger download
      const a = document.createElement("a");
      a.href = url;
      a.download = "tao_transactions.csv"; // Filename for the downloaded file
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a); // Clean up

      setLoading(false);
    } catch (ex) {
      if (ex.response && ex.response.status == 403) {
        navigate("/signin");
        return;
      }
      console.error(ex);
      setLoading(false);
    }
  };

  const handleStartDateChange = (date) => {
    setSearchStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setSearchEndDate(date);
  };

  return (
    <>
      {loading && <Spinner isVisible={fadeSpinner} comment={loadingComment} />}
      <Header />
      <Sidebar />
      <div className="content-body">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="card sub-menu">
                <div className="card-body">
                  <HotkeySubmenu />
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="row">
                <div className="col-xl-12">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Transactions</h4>
                    </div>
                    <div className="card-body">
                      <div className="transaction-table">
                        <div className="table-responsive">
                          <div className="container-fluid">
                            <div className="row">
                              <div className="col">
                                There are{" "}
                                {
                                  <b style={{ color: "greenyellow" }}>
                                    {totalTxns}
                                  </b>
                                }{" "}
                                transactions
                              </div>
                            </div>
                            <div className="row filter">
                              <div className="col-xl-12">
                                <div className="row">
                                  <div className="mb-3 col-xl-3">
                                    <span className="fs-11">Address</span>
                                    <input
                                      type="string"
                                      className="form-control"
                                      placeholder="Address"
                                      value={searchAddr}
                                      onChange={(e) =>
                                        setSearchAddr(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="mb-3 col-xl-3">
                                    <span className="fs-11">From Address</span>

                                    <input
                                      type="string"
                                      className="form-control"
                                      placeholder="From Address"
                                      value={searchFromAddress}
                                      onChange={(e) =>
                                        setSearchFromAddress(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="mb-3 col-xl-3">
                                    <span className="fs-11">To Address</span>

                                    <input
                                      type="string"
                                      className="form-control"
                                      placeholder="To Address"
                                      value={searchToAddress}
                                      onChange={(e) =>
                                        setSearchToAddress(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="mb-3 col-xl-3">
                                    <span className="fs-11">
                                      Transaction Hash
                                    </span>

                                    <input
                                      type="string"
                                      className="form-control"
                                      placeholder="Transaction Hash"
                                      value={searchTxHash}
                                      onChange={(e) =>
                                        setSearchTxHash(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="mb-3 col-xl-2">
                                    <span className="fs-11">
                                      Amount Minimum
                                    </span>

                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="Amount Minimum"
                                      value={searchMinAmount}
                                      onChange={(e) =>
                                        setSearchMinAmount(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="mb-3 col-xl-2">
                                    <span className="fs-11">
                                      Amount Maximum
                                    </span>

                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="Amount Maximum"
                                      value={searchMaxAmount}
                                      onChange={(e) =>
                                        setSearchMaxAmount(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="mb-3 col-xl-2">
                                    <span className="fs-11">Block Number</span>
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="Block Number"
                                      value={searchBlockNumber}
                                      onChange={(e) =>
                                        setSearchBlockNumber(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="mb-3 col-xl-2">
                                    <span className="fs-11">Block Start</span>
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="Block Start"
                                      value={searchBlockStart}
                                      onChange={(e) =>
                                        setSearchBlockStart(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="mb-3 col-xl-2">
                                    <span className="fs-11">Block End</span>
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="Block End"
                                      value={searchBlockEnd}
                                      onChange={(e) =>
                                        setSearchBlockEnd(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="mb-3 col-xl-2">
                                    <span className="fs-11">From Date</span>
                                    <div>
                                      <TimeDatePicker
                                        date={searchStartDate}
                                        onChange={handleStartDateChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="mb-3 col-xl-2">
                                    <span className="fs-11">To Date</span>
                                    <div>
                                      <TimeDatePicker
                                        date={searchEndDate}
                                        onChange={handleEndDateChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="mb-3 col-xl-2">
                                    <span className="fs-11">Order</span>
                                    <select
                                      className="form-control select"
                                      name="country"
                                      value={order}
                                      onChange={(e) => setOrder(e.target.value)}
                                    >
                                      <option value="amount_asc">
                                        amount asc
                                      </option>
                                      <option value="amount_desc">
                                        amount desc
                                      </option>
                                      <option value="block_number_asc">
                                        block number asc
                                      </option>
                                      <option value="block_number_desc">
                                        block number desc
                                      </option>
                                      <option value="timestamp_asc">
                                        timestamp asc
                                      </option>
                                      <option value="timestamp_desc">
                                        timestamp desc
                                      </option>
                                    </select>
                                  </div>
                                  <div className="col-md-2 mb-2">
                                    <span className="fs-11">Page Size</span>
                                    <select
                                      className="form-control"
                                      name="country"
                                      value={pageSize}
                                      onChange={(e) =>
                                        setPageSize(e.target.value)
                                      }
                                    >
                                      <option value="20">PageSize - 20</option>
                                      <option value="50">PageSize - 50</option>
                                      <option value="100">
                                        PageSize - 100
                                      </option>
                                    </select>
                                  </div>
                                  <div className="col-md-auto offset-md-3 text-end">
                                    <button
                                      className="btn btn-dark waves-effect small mt-4"
                                      onClick={clickSearch}
                                    >
                                      <i className="mdi mdi-account-search"></i>
                                      <span> Search</span>
                                    </button>
                                    <button
                                      className="btn btn-dark waves-effect mt-4"
                                      onClick={handleDownloadCSV}
                                    >
                                      <i className="mdi mdi-file-export"></i>
                                      <span> Export CSV</span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-2 mb-2">
                              <div className="col-md-12 justify-content-sm-center">
                                <Pagination
                                  count={txnPages}
                                  Txn
                                  color="primary"
                                  onChange={changeTxPage}
                                />
                              </div>
                            </div>
                          </div>
                          <table className="table table-striped mb-0 table-responsive-sm">
                            <thead>
                              <tr>
                                <th className="text-center">No</th>
                                <th className="text-center">From</th>
                                <th className="text-center">To</th>
                                <th className="text-center">Amount</th>
                                <th className="text-center">Fee</th>
                                <th className="text-center">Date</th>
                                <th className="text-center">Tx Hash</th>
                              </tr>
                            </thead>
                            <tbody>
                              {txns.map((txn, index) => {
                                return (
                                  <tr>
                                    <td className="text-center">
                                      {(Number(currentTxPage) - 1) *
                                        Number(pageSize) +
                                        index +
                                        1}
                                    </td>
                                    <td className="text-center">
                                      <Link
                                        to={`/accounts/${txn.from.ss58}`}
                                        className="link-addr"
                                      >
                                        <span
                                          className={
                                            txn.from.ss58 == searchAddr
                                              ? "text-warning"
                                              : ""
                                          }
                                        >
                                          {txn.from.name
                                            ? shortenAddress(txn.from.name)
                                            : shortenAddress(txn.from.ss58)}
                                        </span>
                                      </Link>{" "}
                                      <Link
                                        className="ms-1"
                                        onClick={() => {
                                          copyToClipboard(txn.from.ss58);
                                        }}
                                      >
                                        <i className="mdi mdi-content-copy"></i>
                                      </Link>
                                    </td>
                                    <td className="text-center">
                                      <Link
                                        to={`/accounts/${txn.to.ss58}`}
                                        className="link-addr"
                                      >
                                        <span
                                          className={
                                            txn.to.ss58 == searchAddr
                                              ? "text-warning"
                                              : ""
                                          }
                                        >
                                          {txn.to.name
                                            ? shortenAddress(txn.to.name)
                                            : shortenAddress(txn.to.ss58)}
                                        </span>
                                      </Link>{" "}
                                      <Link
                                        className="ms-1"
                                        onClick={() => {
                                          copyToClipboard(txn.to.ss58);
                                        }}
                                      >
                                        <i className="mdi mdi-content-copy"></i>
                                      </Link>
                                    </td>
                                    <td className="text-center">
                                      {Number(
                                        parseFloat(
                                          raoToTao(txn.amount)
                                        ).toFixed(3)
                                      ).toLocaleString()}{" "}
                                      <svg
                                        width="1.5em"
                                        height="1.5em"
                                        xmlns="http://www.w3.org/2000/svg"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 3118.1 2721.3"
                                        color="white"
                                        class="text-xl"
                                      >
                                        <title>Bittensor</title>
                                        <path
                                          d="M1808.1,1907.8c-78.4,52.6-159,55.4-240.5,19.1c-76.2-34-111.3-97.6-111.5-179.7c-0.6-236.6-0.2-473.2-0.2-709.9   c0-14.4,0-28.7,0-47.2c-147,0-292.1,0-436.8,0c-9.2-88.9,66.2-185.9,152.2-198.2c36-5.1,72.6-7.9,109-8   c259-0.7,517.9-0.4,776.9-0.4c14.2,0,28.5,0,42.8,0c-1.2,110-78.1,201-202.2,204.2c-126.1,3.3-252.3,0.7-379.9,2.9   c66,27.5,118,69.7,141.7,138c10,28.9,16,60.7,16.3,91.2c1.5,176.1-1,352.3,1.3,528.4C1678.7,1866.8,1722.4,1886,1808.1,1907.8z"
                                          fill="currentColor"
                                        ></path>
                                      </svg>
                                    </td>
                                    <td className="text-center">
                                      {raoToTao(txn.fee)}{" "}
                                      <svg
                                        width="1.5em"
                                        height="1.5em"
                                        xmlns="http://www.w3.org/2000/svg"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 3118.1 2721.3"
                                        color="white"
                                        class="text-xl"
                                      >
                                        <title>Bittensor</title>
                                        <path
                                          d="M1808.1,1907.8c-78.4,52.6-159,55.4-240.5,19.1c-76.2-34-111.3-97.6-111.5-179.7c-0.6-236.6-0.2-473.2-0.2-709.9   c0-14.4,0-28.7,0-47.2c-147,0-292.1,0-436.8,0c-9.2-88.9,66.2-185.9,152.2-198.2c36-5.1,72.6-7.9,109-8   c259-0.7,517.9-0.4,776.9-0.4c14.2,0,28.5,0,42.8,0c-1.2,110-78.1,201-202.2,204.2c-126.1,3.3-252.3,0.7-379.9,2.9   c66,27.5,118,69.7,141.7,138c10,28.9,16,60.7,16.3,91.2c1.5,176.1-1,352.3,1.3,528.4C1678.7,1866.8,1722.4,1886,1808.1,1907.8z"
                                          fill="currentColor"
                                        ></path>
                                      </svg>
                                    </td>
                                    <td className="text-center">
                                      {new Date(
                                        txn.timestamp
                                      ).toLocaleDateString()}{" "}
                                      -{" "}
                                      {new Date(
                                        txn.timestamp
                                      ).toLocaleTimeString()}
                                    </td>
                                    <td className="text-center">
                                      {shortenAddress(
                                        txn.transaction_hash,
                                        10,
                                        4
                                      )}{" "}
                                      <Link
                                        className="ms-1"
                                        onClick={() => {
                                          copyToClipboard(txn.transaction_hash);
                                        }}
                                      >
                                        <i className="mdi mdi-content-copy"></i>
                                      </Link>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </>
  );
}

export default TaoTxns;
