import React, { useEffect, useRef, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Nav, Tab } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-rangeslider/lib/index.css";

import axios from "axios";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

import Footer from "./layout/footer";
import Header from "./layout/header";
import Sidebar from "./layout/sidebar";
import useAuth from "../context/useAuth";
import Spinner from "jsx/components/Spinner";

import { raoToTao } from "jsx/utils/validator";

function Dashboard() {
  const navigate = useNavigate();
  const { isAuthenticated, user } = useAuth();

  const [loading, setLoading] = useState(false);
  const [fadeSpinner, setFadeSpinner] = useState(false); // Controls spinner fade

  const [tick, setTick] = useState(0);
  const prevTick = useRef();

  const [taoTxns, setTaoTxns] = useState([]);
  const prevTaoTxns = useRef();
  const [stakeTxns, setStakeTxns] = useState([]);
  const prevStakeTxns = useRef();
  const [txns, setTxns] = useState([]);
  const prevTxns = useRef();

  const socket = useRef(null);
  const txnsTempSize = 30;
  const intervalRef = useRef(null);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/signin");
      return;
    }

    socket.current = new WebSocket(process.env.REACT_APP_WS_URL);

    socket.current.onopen = () => {
      console.log("WebSocket connection established.");
      socket.current.send(JSON.stringify({ from: "Client", msg: "Connect" }));
    };

    socket.current.onmessage = (event) => {
      stopTimer();
      event.data
        .text()
        .then((text) => {
          const data = JSON.parse(text);
          var addCount = data.tao_txns.length + data.stake_txns.length;

          var removeCount = prevTxns.current.length + addCount - txnsTempSize;
          if (removeCount < 0) {
            removeCount = 0;
          }

          var tmp = prevTxns.current;
          for (var i = 0; i < removeCount; i++) {
            tmp.pop();
          }

          var taoTotal = 0;
          var stakeTotal = 0;
          var unstakeTotal = 0;

          data.tao_txns.forEach((txn) => {
            tmp.push(txn);
            taoTotal = taoTotal + txn.amount;
          });

          data.stake_txns.forEach((txn) => {
            tmp.push(txn);
            if (txn.type == "Stake") {
              stakeTotal = stakeTotal + txn.amount;
            } else if (txn.type == "UnStake") {
              unstakeTotal = unstakeTotal + txn.amount;
            }
          });

          tmp.sort((a, b) => b.block_num - a.block_num);

          setTxns(tmp);

          // TAO TXNS
          removeCount = prevTaoTxns.current.length + 1 - txnsTempSize;

          var tmpTaoBlock = prevTaoTxns.current;
          if (removeCount > 0) {
            tmpTaoBlock.pop();
          }

          var taoBlock = {
            blockNum: data.block_num,
            amount: taoTotal,
            count: data.tao_txns.length,
          };

          tmpTaoBlock.push(taoBlock);
          tmpTaoBlock.sort((a, b) => b.blockNum - a.blockNum);
          setTaoTxns(tmpTaoBlock);

          // Stake TXNS
          removeCount = prevStakeTxns.current.length + 1 - txnsTempSize;

          var tmpStakeBlock = prevStakeTxns.current;
          if (removeCount > 0) {
            tmpStakeBlock.pop();
          }

          var stakeBlock = {
            blockNum: data.block_num,
            stakeAmount: stakeTotal,
            unstakeAmount: unstakeTotal,
            count: data.stake_txns.length,
          };

          tmpStakeBlock.push(stakeBlock);
          tmpStakeBlock.sort((a, b) => b.blockNum - a.blockNum);
          setStakeTxns(tmpStakeBlock);

          startTimer();
        })
        .catch((error) => {
          console.error("Error reading Blob:", error);
        });
    };

    socket.current.onclose = () => {
      console.log("WebSocket connection closed.");
    };

    return () => {
      socket.current.close();
    };
  }, []);

  useEffect(() => {
    prevTxns.current = txns;
  }, [txns]);

  useEffect(() => {
    prevTaoTxns.current = taoTxns;
  }, [taoTxns]);

  useEffect(() => {
    prevStakeTxns.current = stakeTxns;
  }, [stakeTxns]);

  useEffect(() => {
    prevTick.current = tick;
  }, [tick]);

  const startTimer = () => {
    intervalRef.current = setInterval(() => {
      setTick(prevTick.current + 1);
    }, 1000);
  };

  const stopTimer = () => {
    setTick(0);
    clearInterval(intervalRef.current);
  };

  const handleDownloadCSV = async () => {
    try {
      setLoading(true);
      const token = user?.token;

      var response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/job`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            days: 10,
          },
        }
      );
      var datas = response.data.data;

      var response_validators = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/subnets/validators`,
        {
          hotkey: "",
          order: "stake_desc",
          pageNum: 1,
          pageSize: 30,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response_validators);
      var validators = response_validators.data.data;

      datas.sort((a, b) => {
        return new Date(a.created_at) - new Date(b.created_at);
      });

      // ***************************************************************************
      // Sheet Validators
      // ***************************************************************************
      var valExcelData = [];
      var valExcelHeader = ["Subnet ID"];
      validators.forEach((validator) => {
        valExcelHeader.push(
          validator.name ? validator.name : validator.hotkey.ss58
        );
      });
      valExcelData.push(valExcelHeader);
      console.log(datas);
      for (var i = 0; i < datas[datas.length - 1].subnet_info.length; i++) {
        var rowData = [i];
        for (var j = 0; j < validators.length; j++) {
          var isAdded = false;
          validators[j].subnet_dominance.forEach((subnet_dominance) => {
            if (subnet_dominance.netuid == i) {
              rowData.push(
                Number(parseFloat(subnet_dominance.dominance).toFixed(3))
              );
              isAdded = true;
            }
          });
          if (!isAdded) {
            rowData.push("");
          }
        }
        valExcelData.push(rowData);
      }
      // ****************************************************************************
      // Sheet Analyze
      // ****************************************************************************

      var excelData = [];

      var excelHeader = ["Descriptions"];
      // create header
      datas.forEach((data) => {
        excelHeader.push(
          new Date(data.created_at).toLocaleDateString() +
            " " +
            new Date(data.created_at).toLocaleTimeString()
        );
      });

      excelData.push(excelHeader);
      excelData.push(["Econometric Data"]);

      var excelRowData = ["Circulating Supply (C_S)"];
      datas.forEach((data) => {
        excelRowData.push(Math.round(raoToTao(data.circulating_supply)) + " T");
      });
      excelData.push(excelRowData);

      excelRowData = ["Supply % Released (C_S/21M)"];
      datas.forEach((data) => {
        excelRowData.push(parseFloat(data.supply_percent).toFixed(2) + "%");
      });
      excelData.push(excelRowData);

      excelRowData = [
        "Daily TAO Emissions (7200 - Root recycled - Subnet miner reg costs recycled)",
      ];
      datas.forEach((data) => {
        if (
          data.subnet_info.length > 0 &&
          data.subnet_info[0].emission &&
          data.neuron_reg_cost
        ) {
          excelRowData.push(
            Math.round(
              raoToTao(
                7200000000000 *
                  (1 - data.subnet_info[0].emission / 1000000000) -
                  data.neuron_reg_cost
              )
            ) + " T"
          );
        } else {
          excelRowData.push("");
        }
      });
      excelData.push(excelRowData);

      excelRowData = ["Effective Daily Inflation (/365)"];
      datas.forEach((data) => {
        if (
          data.circulating_supply &&
          data.subnet_info.length > 0 &&
          data.subnet_info[0].emission &&
          data.neuron_reg_cost
        ) {
          excelRowData.push(
            parseFloat(
              ((7200000000000 *
                (1 - data.subnet_info[0].emission / 1000000000) -
                data.neuron_reg_cost) /
                data.circulating_supply) *
                100
            ).toFixed(2) + " %"
          );
        } else {
          excelRowData.push("");
        }
      });
      excelData.push(excelRowData);

      excelRowData = [
        "Effective Network Inflation (Daily Emissions*365 / C_S)",
      ];
      datas.forEach((data) => {
        if (
          data.circulating_supply &&
          data.subnet_info.length > 0 &&
          data.subnet_info[0].emission &&
          data.neuron_reg_cost
        ) {
          excelRowData.push(
            parseFloat(
              ((7200000000000 *
                (1 - data.subnet_info[0].emission / 1000000000) -
                data.neuron_reg_cost) /
                data.circulating_supply) *
                100 *
                365
            ).toFixed(2) + " %"
          );
        } else {
          excelRowData.push("");
        }
      });
      excelData.push(excelRowData);

      excelRowData = [""];

      excelData.push(excelRowData);

      excelRowData = ["Accounts and Transfers"];

      excelData.push(excelRowData);

      excelRowData = ["Total Accounts (taoedge.io)"];
      datas.forEach((data) => {
        excelRowData.push(data.total_accounts);
      });
      excelData.push(excelRowData);

      excelRowData = ["Accounts 0.01 - 1"];
      datas.forEach((data) => {
        excelRowData.push(data.accounts_1);
      });
      excelData.push(excelRowData);

      excelRowData = ["Accounts 1.01-10 TAO"];
      datas.forEach((data) => {
        excelRowData.push(data.accounts_10);
      });
      excelData.push(excelRowData);

      excelRowData = ["Accounts 10.01 - 50 TAO"];
      datas.forEach((data) => {
        excelRowData.push(data.accounts_50);
      });
      excelData.push(excelRowData);

      excelRowData = ["Accounts 50.01 - 100 TAO"];
      datas.forEach((data) => {
        excelRowData.push(data.accounts_100);
      });
      excelData.push(excelRowData);

      excelRowData = ["Accounts 100.01 - 500 TAO"];
      datas.forEach((data) => {
        excelRowData.push(data.accounts_500);
      });
      excelData.push(excelRowData);

      excelRowData = ["Accounts 500.01 - 1000 TAO"];
      datas.forEach((data) => {
        excelRowData.push(data.accounts_1000);
      });
      excelData.push(excelRowData);

      excelRowData = ["Accounts 1000.01 - 5000 TAO"];
      datas.forEach((data) => {
        excelRowData.push(data.accounts_5000);
      });
      excelData.push(excelRowData);

      excelRowData = ["Accounts 5000.01 - 30k TAO"];
      datas.forEach((data) => {
        excelRowData.push(data.accounts_30000);
      });
      excelData.push(excelRowData);

      excelRowData = ["Accounts 30000.01+ TAO (including CEXs/DEXs)"];
      datas.forEach((data) => {
        excelRowData.push(data.accounts_more);
      });
      excelData.push(excelRowData);

      excelRowData = ["Total Transfers > 1 TAO (1000000000 on taoedge.io)"];
      datas.forEach((data) => {
        excelRowData.push(data.transfer_1);
      });
      excelData.push(excelRowData);

      excelRowData = ["Total Transfers > 10 TAO"];
      datas.forEach((data) => {
        excelRowData.push(data.transfer_10);
      });
      excelData.push(excelRowData);

      excelRowData = ["Total Transfers > 100 TAO"];
      datas.forEach((data) => {
        excelRowData.push(data.transfer_100);
      });
      excelData.push(excelRowData);

      excelRowData = ["Total Transfers > 1000 TAO"];
      datas.forEach((data) => {
        excelRowData.push(data.transfer_1000);
      });
      excelData.push(excelRowData);

      excelRowData = ["Total Transfers > 10000 TAO"];
      datas.forEach((data) => {
        excelRowData.push(data.transfer_10000);
      });
      excelData.push(excelRowData);

      // **********************************************************************************
      // Staking Metrics
      // **********************************************************************************
      excelRowData = [""];
      excelData.push(excelRowData);

      excelRowData = ["Staking Metrics"];
      excelData.push(excelRowData);

      excelRowData = ["Total Nominators (taoedge.io)"];
      datas.forEach((data) => {
        excelRowData.push(data.total_nominators);
      });
      excelData.push(excelRowData);

      excelRowData = ["Total Stake (taostats.io)"];
      datas.forEach((data) => {
        excelRowData.push(Math.round(raoToTao(data.total_staked)) + " T");
      });
      excelData.push(excelRowData);

      excelRowData = ["Percentage of C_S Staked"];
      datas.forEach((data) => {
        excelRowData.push(parseFloat(data.percentage_staked).toFixed(2) + "%");
      });
      excelData.push(excelRowData);

      excelRowData = ["Lowest APY % (Polychain)"];
      datas.forEach((data) => {
        excelRowData.push(
          data.polychainAPR
            ? parseFloat(Number(data.polychainAPR) * 100).toFixed(3) + "%"
            : ""
        );
      });
      excelData.push(excelRowData);

      excelRowData = ["Highest APY % (incl. WC, taostats)"];
      datas.forEach((data) => {
        excelRowData.push(
          data.maxAPR
            ? parseFloat(Number(data.maxAPR) * 100).toFixed(3) + "%"
            : ""
        );
      });
      excelData.push(excelRowData);

      // **********************************************************************************
      // Subnet Data
      // **********************************************************************************
      excelRowData = [""];
      excelData.push(excelRowData);

      excelRowData = ["Subnet Data"];
      excelData.push(excelRowData);

      excelRowData = ["Number of Subnets (excluding SN0)"];
      datas.forEach((data) => {
        excelRowData.push(data.total_subnets);
      });
      excelData.push(excelRowData);

      excelRowData = [
        "Total TAO locked to Subnets (Subnet overall taoedge csv)",
      ];
      datas.forEach((data) => {
        excelRowData.push(
          data.total_tao_locked
            ? Math.round(raoToTao(data.total_tao_locked)) + " T"
            : ""
        );
      });
      excelData.push(excelRowData);
      excelRowData = ["Percentage of Locked Subnet Tokens over C_S"];
      datas.forEach((data) => {
        if (data.circulating_supply && data.total_tao_locked) {
          excelRowData.push(
            parseFloat(
              (data.total_tao_locked / data.circulating_supply) * 100
            ).toFixed(2) + " %"
          );
        } else {
          excelRowData.push("");
        }
      });
      excelData.push(excelRowData);
      excelRowData = ["Root Network % (Recycled)"];
      datas.forEach((data) => {
        excelRowData.push(
          data.subnet_info.length > 0
            ? parseFloat(
                (Number(data.subnet_info[0].emission) / 1000000000) * 100
              ).toFixed(2) + "%"
            : ""
        );
      });
      excelData.push(excelRowData);
      excelRowData = [
        "Total 24H Neuron Reg Costs Recycled (by uploading taoedge.io CSV to ChatGpt)",
      ];
      datas.forEach((data) => {
        excelRowData.push(
          data.neuron_reg_cost ? Math.round(raoToTao(data.neuron_reg_cost)) : ""
        );
      });
      excelData.push(excelRowData);
      excelRowData = ["Last Registration Cost of Subnet (taostats.io)"];
      datas.forEach((data) => {
        excelRowData.push(
          data.last_reg_cost ? Math.round(raoToTao(data.last_reg_cost)) : ""
        );
      });
      excelData.push(excelRowData);
      excelRowData = ["Number of Validators > 1k TAO (taoedge.io)"];
      datas.forEach((data) => {
        excelRowData.push(data.validators_1k ? data.validators_1k : "");
      });
      excelData.push(excelRowData);
      excelRowData = [
        "Total Number of Active_Miners (taoedge.io CSV) (excluding SN0)",
      ];
      datas.forEach((data) => {
        excelRowData.push(
          data.total_active_miners ? data.total_active_miners : ""
        );
      });
      excelData.push(excelRowData);
      excelRowData = ["Total Number of Coldkeys (from below)"];
      excelData.push(excelRowData);

      // **********************************************************************************
      // Markets Data
      // **********************************************************************************
      excelRowData = [""];
      excelData.push(excelRowData);

      excelRowData = ["Markets Data"];
      excelData.push(excelRowData);

      excelRowData = [
        "Total TAO supply on exchanges (= add up all the CEX wallets) (taostats)",
      ];
      datas.forEach((data) => {
        excelRowData.push(
          data.total_supply_exchanges
            ? Math.round(raoToTao(data.total_supply_exchanges)) + " T"
            : ""
        );
      });
      excelData.push(excelRowData);
      excelRowData = ["Change in CEX Balance "];
      var prev_exchange_value = 0;
      datas.forEach((data) => {
        excelRowData.push(
          prev_exchange_value != 0
            ? Math.round(
                raoToTao(
                  Number(data.total_supply_exchanges) -
                    Number(prev_exchange_value)
                )
              ) + " T"
            : ""
        );
        prev_exchange_value = data.total_supply_exchanges
          ? data.total_supply_exchanges
          : 0;
      });
      excelData.push(excelRowData);
      excelRowData = ["Total TAO Supply on DeFi rails (e.g. TaoBridge)"];
      datas.forEach((data) => {
        excelRowData.push(
          data.tao_bridge_balance
            ? Math.round(raoToTao(data.tao_bridge_balance)) + " T"
            : ""
        );
      });
      excelData.push(excelRowData);
      excelRowData = ["Price of $TAO (API from CoinGecko)"];
      datas.forEach((data) => {
        excelRowData.push(
          data.coingecko_tao_price
            ? "$" + Math.round(data.coingecko_tao_price)
            : ""
        );
      });
      excelData.push(excelRowData);
      excelRowData = ["Market Cap of $TAO (using total issuance = C_S)"];
      datas.forEach((data) => {
        excelRowData.push(
          data.coingecko_tao_price && data.circulating_supply
            ? "$" +
                Math.round(
                  parseFloat(raoToTao(data.coingecko_tao_price)) *
                    parseFloat(data.circulating_supply)
                )
            : ""
        );
      });
      excelData.push(excelRowData);
      excelRowData = ["Market Cap of $TAO (using reduced C_S) (coingecko)"];
      datas.forEach((data) => {
        excelRowData.push(
          data.coingecko_market_cap
            ? "$" + Math.round(data.coingecko_market_cap)
            : ""
        );
      });
      excelData.push(excelRowData);
      excelRowData = ["24H Trading Vol (rolling basis, Coingecko.com)"];
      datas.forEach((data) => {
        excelRowData.push(
          data.coingecko_trading_volume_24h
            ? "$" + Math.round(data.coingecko_trading_volume_24h)
            : ""
        );
      });
      excelData.push(excelRowData);
      excelRowData = [
        "Market Dominance (https://lunarcrush.com/discover/tao-bittensor)",
      ];
      excelData.push(excelRowData);

      // **********************************************************************************
      // Perps Market data: (https://coinalyze.net/bittensor/open-interest/)
      // **********************************************************************************
      excelRowData = [""];
      excelData.push(excelRowData);

      excelRowData = [
        "Perps Market data: (https://coinalyze.net/bittensor/open-interest/)",
      ];
      excelData.push(excelRowData);

      excelRowData = ["Total Open Interest ($)"];
      datas.forEach((data) => {
        if (data.coinanly_open_interest && data.coingecko_tao_price) {
          excelRowData.push(
            "$" +
              Math.round(
                Number(data.coinanly_open_interest) *
                  Number(data.coingecko_tao_price)
              )
          );
        } else {
          excelRowData.push("");
        }
      });
      excelData.push(excelRowData);
      excelRowData = ["OI Share"];
      datas.forEach((data) => {
        if (data.coinanly_oi_share) {
          excelRowData.push(data.coinanly_oi_share);
        } else {
          excelRowData.push("");
        }
      });
      excelData.push(excelRowData);
      excelRowData = [
        "Average funding rates across exchanges (weighted by CEX open interest)",
      ];
      datas.forEach((data) => {
        if (data.coinanly_funding_rate) {
          excelRowData.push(
            parseFloat(data.coinanly_funding_rate).toFixed(4) + "(%)"
          );
        } else {
          excelRowData.push("");
        }
      });
      excelData.push(excelRowData);
      excelRowData = ["24H Longs Liqs"];
      datas.forEach((data) => {
        if (data.coinanly_long_liq) {
          excelRowData.push(
            "$" +
              Math.round(
                Number(data.coinanly_long_liq) *
                  Number(data.coingecko_tao_price)
              )
          );
        } else {
          excelRowData.push("");
        }
      });
      excelData.push(excelRowData);
      excelRowData = ["24H Short Liqs"];
      datas.forEach((data) => {
        if (data.coinanly_short_liq) {
          excelRowData.push(
            "$" +
              Math.round(
                Number(data.coinanly_short_liq) *
                  Number(data.coingecko_tao_price)
              )
          );
        } else {
          excelRowData.push("");
        }
      });
      excelData.push(excelRowData);

      // **********************************************************************************
      // Socials Data:
      // **********************************************************************************
      excelRowData = [""];
      excelData.push(excelRowData);

      excelRowData = ["Socials Data"];
      excelData.push(excelRowData);

      excelRowData = [
        "Engagements (https://lunarcrush.com/discover/tao-bittensor)",
      ];
      excelData.push(excelRowData);
      excelRowData = ["Mentions"];
      excelData.push(excelRowData);
      excelRowData = [
        "Discord members for Bittensor (safari https://discord.com/invite/bittensor)",
      ];
      datas.forEach((data) => {
        excelRowData.push(data.discord_members ? data.discord_members : "");
      });
      excelData.push(excelRowData);

      // **********************************************************************************
      // Subnet Specific Data
      // **********************************************************************************
      excelRowData = [""];
      excelData.push(excelRowData);

      excelRowData = ["Subnet Specific Data"];
      excelData.push(excelRowData);

      excelRowData = ["Subnet ID => Emission|Recycled|N Coldkeys"];
      excelData.push(excelRowData);

      for (var i = 0; i < datas[datas.length - 1].subnet_info.length; i++) {
        excelRowData = [i.toString()];
        datas.forEach((data) => {
          excelRowData.push(
            data.subnet_info
              ? i < data.subnet_info.length
                ? (
                    (parseFloat(data.subnet_info[i].emission) / 1000000000) *
                    100
                  ).toPrecision(4) +
                  "(%)" +
                  "|" +
                  Number(
                    raoToTao(data.subnet_info[i].recycled_24h)
                  ).toPrecision(4) +
                  " T"
                : ""
              : ""
          );
        });
        excelData.push(excelRowData);
      }

      // Create a workbook and a worksheet
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Analyze");
      const validatorWorkSheet = workbook.addWorksheet("Validators");

      const headerRow = worksheet.addRow(excelHeader);
      headerRow.font = { bold: true, color: { argb: "FFFFFF" } };
      headerRow.height = 30;
      headerRow.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "262626" },
      };

      const valHeaderRow = validatorWorkSheet.addRow(valExcelHeader);
      valHeaderRow.font = { bold: true, color: { argb: "FFFFFF" } };
      valHeaderRow.height = 30;
      valHeaderRow.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "262626" },
      };

      validatorWorkSheet.getCell("B1").alignment = { wrapText: true };
      validatorWorkSheet.getCell("C1").alignment = { wrapText: true };
      validatorWorkSheet.getCell("D1").alignment = { wrapText: true };
      validatorWorkSheet.getCell("E1").alignment = { wrapText: true };
      validatorWorkSheet.getCell("F1").alignment = { wrapText: true };
      validatorWorkSheet.getCell("G1").alignment = { wrapText: true };
      validatorWorkSheet.getCell("H1").alignment = { wrapText: true };
      validatorWorkSheet.getCell("I1").alignment = { wrapText: true };
      validatorWorkSheet.getCell("J1").alignment = { wrapText: true };
      validatorWorkSheet.getCell("K1").alignment = { wrapText: true };
      validatorWorkSheet.getCell("L1").alignment = { wrapText: true };
      validatorWorkSheet.getCell("M1").alignment = { wrapText: true };
      validatorWorkSheet.getCell("N1").alignment = { wrapText: true };
      validatorWorkSheet.getCell("O1").alignment = { wrapText: true };
      validatorWorkSheet.getCell("P1").alignment = { wrapText: true };
      validatorWorkSheet.getCell("Q1").alignment = { wrapText: true };
      validatorWorkSheet.getCell("R1").alignment = { wrapText: true };
      validatorWorkSheet.getCell("S1").alignment = { wrapText: true };
      validatorWorkSheet.getCell("T1").alignment = { wrapText: true };
      validatorWorkSheet.getCell("U1").alignment = { wrapText: true };
      validatorWorkSheet.getCell("V1").alignment = { wrapText: true };
      validatorWorkSheet.getCell("W1").alignment = { wrapText: true };
      validatorWorkSheet.getCell("X1").alignment = { wrapText: true };
      validatorWorkSheet.getCell("Y1").alignment = { wrapText: true };
      validatorWorkSheet.getCell("Z1").alignment = { wrapText: true };
      validatorWorkSheet.getCell("AA1").alignment = { wrapText: true };
      validatorWorkSheet.getCell("AB1").alignment = { wrapText: true };
      validatorWorkSheet.getCell("AC1").alignment = { wrapText: true };
      validatorWorkSheet.getCell("AD1").alignment = { wrapText: true };
      validatorWorkSheet.getCell("AE1").alignment = { wrapText: true };
      validatorWorkSheet.getCell("AF1").alignment = { wrapText: true };
      validatorWorkSheet.getCell("AG1").alignment = { wrapText: true };

      // Add data
      for (var i = 1; i < excelData.length; i++) {
        const dataRow = worksheet.addRow(excelData[i]);
        if (
          excelData[i][0].includes("Econometric Data") ||
          excelData[i][0].includes("Accounts and Transfers") ||
          excelData[i][0].includes("Staking Metrics") ||
          excelData[i][0].includes("Subnet Data") ||
          excelData[i][0].includes("Markets Data") ||
          excelData[i][0].includes(
            "Perps Market data: (https://coinalyze.net/bittensor/open-interest/)"
          ) ||
          excelData[i][0].includes("Socials Data") ||
          excelData[i][0].includes("Subnet Specific Data")
        ) {
          dataRow.font = { bold: true, color: { argb: "FFFFFF" } };
          dataRow.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "505050" },
          };
        } else if (
          excelData[i][0].includes("Subnet ID => Emission|Recycled|N Coldkeys")
        ) {
          dataRow.font = { bold: true, color: { argb: "FFFFFF" } };
          dataRow.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "707070" },
          };
          dataRow.height = 30;
        }
      }

      // Add data
      for (var i = 1; i < valExcelData.length; i++) {
        const dataRow = validatorWorkSheet.addRow(valExcelData[i]);
      }

      // Calculate column widths dynamically
      worksheet.columns = excelData[0].map((_, colIndex) => {
        const maxWidth = excelData.reduce((max, row) => {
          const cellValue = row[colIndex] ? row[colIndex].toString() : "";
          return Math.max(max, cellValue.length);
        }, 0);
        return { width: maxWidth + 2 }; // Add padding for better appearance
      });

      // validatorWorkSheet.columns = valExcelData[0].map((_, colIndex) => {
      //   const maxWidth = valExcelData.reduce((max, row) => {
      //     const cellValue = row[colIndex] ? row[colIndex].toString() : "";
      //     return Math.max(max, cellValue.length);
      //   }, 0);
      //   return { width: maxWidth + 2 }; // Add padding for better appearance
      // });

      // Align text to middle for all cells in row 2
      // const row = worksheet.getRow(1);
      // row.eachCell((cell) => {
      //   cell.alignment = { vertical: "middle", horizontal: "center" };
      // });

      // Align all cells to center
      worksheet.eachRow((row) => {
        row.eachCell((cell) => {
          if (cell.col == 1) {
            cell.alignment = { vertical: "middle", horizontal: "right" };
          } else {
            cell.alignment = { vertical: "middle", horizontal: "center" };
          }
        });
      });

      // Freeze the header row
      worksheet.views = [
        { state: "frozen", xSplit: 1, ySplit: 1 }, // Freeze the first row
      ];

      validatorWorkSheet.views = [
        { state: "frozen", xSplit: 1, ySplit: 1 }, // Freeze the first row
      ];

      // Export to Excel file
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: "application/octet-stream" });
      saveAs(blob, "Analyze.xlsx");

      setLoading(false);
    } catch (ex) {
      if (ex.response && ex.response.status == 403) {
        navigate("/signin");
        return;
      }
      console.error(ex);
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Spinner isVisible={fadeSpinner} />}
      <Header />
      <Sidebar />
      <div className="content-body" id="dashboard">
        <div className="container-fluid pt-2">
          <div className="row">
            <div className="col-xl-6 col-xxl-12 col-lg-12 col-xxl-6">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Economics & Staking</h4>
                  <span>
                    24h Change <strong className="text-success"> +5.30%</strong>
                    <button className="btn btn-primary waves-effect fs-11 ms-3 pt-1 pb-1 mt-0 mb-0">
                      <i className="mdi mdi-file-export"></i>
                      <span> Export CSV</span>
                    </button>
                  </span>
                </div>
                <div className="card-body">
                  <div className="row  justify-content-between">
                    <div className="col col-sm-auto col-6">
                      <p className="mb-2">Circulating Supply</p>
                      <h6>814,107.51 USD</h6>
                    </div>
                    <div className="col col-sm-auto col-6">
                      <p className="mb-2">Subnets</p>
                      <h6>10,326.19 USD</h6>
                    </div>
                    <div className="col col-sm-auto col-6">
                      <p className="mb-2">Total Staked</p>
                      <h6>10,343.94 USD</h6>
                    </div>
                    <div className="col col-sm-auto col-6">
                      <p className="mb-2">Neuron Reg Costs</p>
                      <h6>10,383.51 USD</h6>
                    </div>
                    <div className="col col-sm-auto col-6">
                      <p className="mb-2">Total Transfer</p>
                      <h6>0.1392%</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-xxl-12 col-lg-12 col-xxl-6">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Accounts and Transfer</h4>
                  <span>
                    Last Update : 2024-12-01 23:59:48
                    <button
                      className="btn btn-primary waves-effect fs-11 ms-3 pt-1 pb-1 mt-0 mb-0"
                      onClick={handleDownloadCSV}
                    >
                      <i className="mdi mdi-file-export"></i>
                      <span> Export CSV</span>
                    </button>
                  </span>
                </div>
                <div className="card-body">
                  <div className="row justify-content-between">
                    <div className="col col-sm-auto col-6">
                      <p className="mb-2">Total Accounts</p>
                      <h6>34.00 USD</h6>
                    </div>
                    <div className="col col-sm-auto col-6">
                      <p className="mb-2">Accounts(0.01 ~ 1)</p>
                      <h6>47.00 USD</h6>
                    </div>
                    <div className="col col-sm-auto col-6">
                      <p className="mb-2">Accounts(1.01 ~ 10)</p>
                      <h6>92.00 USD</h6>
                    </div>
                    <div className="col col-sm-auto col-6">
                      <p className="mb-2">Accounts(10.01 ~ 50)</p>
                      <h6>58.00 USD</h6>
                    </div>
                    <div className="col col-sm-auto col-6">
                      <p className="mb-2">Accounts(50.01 ~ 100)</p>
                      <h6>15.00 USD</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-2 col-xxl-12 col-lg-12 col-xxl-2">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Market News</h4>
                </div>
                <PerfectScrollbar>
                  <div className="card-body market-news">
                    <div className="row">
                      <div className="col-xl-12">
                        <img
                          className="img-fluid"
                          src={require("./../../images/blog/1.jpg")}
                          alt="..."
                          height="100"
                        />
                        <Link to={"#"} className="news-content">
                          <h6 className="mt-2 mb-3">
                            The Cryptocurrency Act of {new Date().getFullYear()}{" "}
                            Is ‘Dead on Arrival,’ Washington Tells Sponsors
                          </h6>
                        </Link>
                      </div>
                      <div className="col-xl-12">
                        <img
                          className="img-fluid"
                          src={require("./../../images/blog/2.jpg")}
                          alt="..."
                        />
                        <Link to={"#"} className="news-content">
                          <h6 className="mt-2 mb-3">
                            UK Trade Negotiators Eye Blockchain Provisions in
                            Coming US Trade Talks
                          </h6>
                        </Link>
                      </div>
                      <div className="col-xl-12">
                        <img
                          className="img-fluid"
                          src={require("./../../images/blog/3.jpg")}
                          alt="..."
                        />
                        <Link to={"#"} className="news-content">
                          <h6 className="mt-2 mb-3">
                            Bitcoin Options Saw Record Volume of $198M Amid
                            Recent Price Drop
                          </h6>
                        </Link>
                      </div>
                      <div className="col-xl-12">
                        <img
                          className="img-fluid"
                          src={require("./../../images/blog/4.jpg")}
                          alt="..."
                        />
                        <Link to={"#"} className="news-content">
                          <h6 className="mt-2 mb-3">
                            Blockforce Capital’s Crypto Fund Captures 86% of
                            Bitcoin’s Upside in {new Date().getFullYear()}{" "}
                          </h6>
                        </Link>
                      </div>
                    </div>
                  </div>
                </PerfectScrollbar>
              </div>
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Your Portfolio </h4>
                </div>
                <PerfectScrollbar>
                  <div className="card-body balance-widget">
                    <div className="total-balance">
                      <h3>$ 63411.00</h3>
                      <h6>Total Balance</h6>
                    </div>

                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Type</th>
                            <th>Size</th>
                            <th>USD</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>BTC</td>
                            <td>0.000242</td>
                            <td>0.125 USD</td>
                          </tr>
                          <tr>
                            <td>LTC</td>
                            <td>0.000242 </td>
                            <td>0.125 USD</td>
                          </tr>
                          <tr>
                            <td>XRP</td>
                            <td>0.000242 </td>
                            <td>0.125 USD</td>
                          </tr>
                          <tr>
                            <td>ETH</td>
                            <td>0.000242 </td>
                            <td>0.125 USD</td>
                          </tr>
                          <tr>
                            <td>XTZ</td>
                            <td>0.000242 </td>
                            <td>0.125 USD</td>
                          </tr>
                          <tr>
                            <td>EOS</td>
                            <td>0.000242 </td>
                            <td>0.125 USD</td>
                          </tr>
                          <tr>
                            <td>Dash</td>
                            <td>0.000242 </td>
                            <td>0.125 USD</td>
                          </tr>
                          <tr>
                            <td>USDT</td>
                            <td>0.000242 </td>
                            <td>0.125 USD</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </PerfectScrollbar>
              </div>
            </div>
            <div className="col-xl-8 col-xxl-12 col-lg-12 col-xxl-8">
              <div className="row">
                <div className="col-xl-12">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Transactions</h4>
                      <span>
                        <i className="mdi mdi-history me-2"></i>
                        {tick} sec
                      </span>
                    </div>
                    <div className="card-body">
                      <table className="table table-striped mb-0 table-responsive-sm">
                        <thead>
                          <tr>
                            <th className="text-center">Block Number</th>
                            <th className="text-center">From | Staker</th>
                            <th className="text-center">To</th>
                            <th className="text-center">Amount</th>
                            <th className="text-center">Type</th>
                          </tr>
                        </thead>
                        <tbody>
                          {txns.map((txn, index) => {
                            return (
                              <tr key={index}>
                                <td className="text-center">{txn.block_num}</td>
                                <td className="text-center fs-11">
                                  {txn.type == "Transfer"
                                    ? txn.from
                                    : txn.address}
                                </td>
                                <td className="text-center fs-11">{txn.to}</td>
                                <td className="text-center">
                                  {raoToTao(txn.amount)}
                                </td>
                                <td className="text-center">
                                  <span
                                    className={`badge ${
                                      txn.type == "Transfer"
                                        ? "badge-success"
                                        : txn.type == "Stake"
                                        ? "badge-type1"
                                        : "badge-type2"
                                    }`}
                                  >
                                    {txn.type}
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-xxl-12 col-lg-12 col-xxl-2">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Trade History</h4>
                </div>
                <PerfectScrollbar>
                  <div className="card-body trade-history">
                    <div className="table-responsive">
                      <table className="table table-borderless">
                        <thead>
                          <tr>
                            <th scope="col">Block</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Txns</th>
                          </tr>
                        </thead>
                        <tbody>
                          {taoTxns.map((block, index) => {
                            return (
                              <tr>
                                <td className="text-danger fs-11">
                                  {block.blockNum}
                                </td>
                                <td>{raoToTao(block.amount)}</td>
                                <td>{block.count}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </PerfectScrollbar>
              </div>
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Staking History</h4>
                </div>
                <PerfectScrollbar>
                  <div className="card-body trade-history">
                    <div className="table-responsive">
                      <table className="table table-borderless">
                        <thead>
                          <tr>
                            <th scope="col">Block</th>
                            <th scope="col">Stake/Unstake</th>
                            <th scope="col">Txns</th>
                          </tr>
                        </thead>
                        <tbody>
                          {stakeTxns.map((block, index) => {
                            return (
                              <tr>
                                <td className="text-danger fs-11">
                                  {block.blockNum}
                                </td>
                                <td>
                                  {raoToTao(block.stakeAmount)}/
                                  {raoToTao(block.unstakeAmount)}
                                </td>
                                <td>{block.count}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </PerfectScrollbar>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Dashboard;
