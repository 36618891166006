import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Nav, Tab } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-rangeslider/lib/index.css";
import Pagination from "@mui/material/Pagination";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import axios from "axios";

import Spinner from "jsx/components/Spinner";
import Footer from "jsx/pages/layout/footer";
import Header from "jsx/pages/layout/header";
import Sidebar from "jsx/pages/layout/sidebar";
import { raoToTao } from "jsx/utils/validator";
import SettingsSubmenu from "jsx/pages/layout/submenu_settings";
import useAuth from "../../../context/useAuth";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#131722",
  border: "1px solid #303030",
  boxShadow: 24,
  p: 4,
};

function Logs() {
  const navigate = useNavigate();

  const { isAuthenticated , user } = useAuth();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/signin");
      return;
    }
  }, []);


  const [loading, setLoading] = useState(false);
  const [fadeSpinner, setFadeSpinner] = useState(false); // Controls spinner fade

  const [totalLogs, setTotalLogs] = useState(0);
  const [searchNameOrEmail, setSearchNameOrEmail] = useState("");
  const [logs, setLogs] = useState([]);

  const [searchUserStatus, setSearchUserStatus] = useState("");
  const [searchOrder, setSearchOrder] = useState("DESC");
  const [pageSize, setPageSize] = useState("50");
  const [totalPages, setTotalPages] = useState(1);
  const [currentLogPage, setCurrentLogPage] = useState(1);

  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  useEffect(() => {
    clickSearch();
  }, [currentLogPage]);

  const clickSearch = async () => {
    try {
      setLoading(true);
      const token = user?.token;

      var response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/logs`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            search: searchNameOrEmail,
            order: searchOrder,
            pageNo: currentLogPage,
            count: pageSize,
          },
        }
      );
      console.log(response);
      setLogs(response.data.data);
      setTotalLogs(response.data.total);
      setTotalPages(
        Math.floor(Number(response.data.total) / Number(pageSize)) + 1
      );
      setLoading(false);
    } catch (ex) {
      if (ex.response && ex.response.status == 403) {
        navigate("/signin");
        return;
      }
      console.error(ex);
      setLoading(false);
    }
  };

  const changeUserPage = (event, value) => {
    setCurrentLogPage(value);
  };

  const handleCloseDeleteUserModal = () => setOpenDelete(false);
  const handleCloseEditUserModal = () => setOpenEdit(false);

  return (
    <>
      {loading && <Spinner isVisible={fadeSpinner} />}
      <Header />
      <Sidebar />
      <div className="content-body">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="card sub-menu">
                <div className="card-body">
                  <SettingsSubmenu />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-12">
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Users</h4>
                  </div>
                  <div className="card-body">
                    <div className="transaction-table">
                      <div className="table-responsive">
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col">
                              There are{" "}
                              {
                                <b style={{ color: "greenyellow" }}>
                                  {totalLogs}
                                </b>
                              }{" "}
                              logs
                            </div>
                          </div>
                          <div className="row filter">
                            <div className="col-xl-12">
                              <div className="row">
                                <div className="mb-3 col-xl-2">
                                  <span className="fs-11">
                                    Name | Email | IP
                                  </span>
                                  <input
                                    type="string"
                                    className="form-control"
                                    placeholder="Name | Email | IP"
                                    value={searchNameOrEmail}
                                    onChange={(e) =>
                                      setSearchNameOrEmail(e.target.value)
                                    }
                                  />
                                </div>

                                <div className="mb-3 col-xl-2">
                                  <span className="fs-11">Order</span>
                                  <select
                                    className="form-control"
                                    name="country"
                                    value={searchOrder}
                                    onChange={(e) =>
                                      setSearchOrder(e.target.value)
                                    }
                                  >
                                    <option value="ASC">Timestamp ASC</option>
                                    <option value="DESC">Timestamp DESC</option>
                                  </select>
                                </div>

                                <div className="mb-3 col-xl-2">
                                  <span className="fs-11">Page Size</span>
                                  <select
                                    className="form-control"
                                    name="country"
                                    value={pageSize}
                                    onChange={(e) =>
                                      setPageSize(e.target.value)
                                    }
                                  >
                                    <option value="20">PageSize - 20</option>
                                    <option value="50">PageSize - 50</option>
                                    <option value="100">PageSize - 100</option>
                                  </select>
                                </div>

                                <div className="col-md-auto offset-md-1 text-end">
                                  <button
                                    className="btn btn-dark waves-effect small mt-4"
                                    onClick={clickSearch}
                                  >
                                    <i className="mdi mdi-account-search"></i>
                                    <span> Search</span>
                                  </button>
                                  <button className="btn btn-dark waves-effect mt-4">
                                    <i className="mdi mdi-file-export"></i>
                                    <span> Export CSV</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-2 mb-2">
                            <div className="col-md-12 justify-content-sm-center">
                              <Pagination
                                count={totalPages}
                                setTotalPages="primary"
                                onChange={changeUserPage}
                              />
                            </div>
                          </div>
                          <table className="table table-striped mb-0 table-responsive-sm">
                            <thead>
                              <tr>
                                <th className="text-center">No</th>
                                <th className="text-center">User Email</th>
                                <th className="text-center">Timestamp</th>
                                <th className="text-center">IP Address</th>
                                <th className="text-center">URL</th>
                              </tr>
                            </thead>
                            <tbody>
                              {logs.map((log, index) => {
                                return (
                                  <tr>
                                    <td className="text-center">{index + 1}</td>
                                    <td className="text-center">{log.user}</td>
                                    <td className="text-center">
                                      {new Date(
                                        log.created_at
                                      ).toLocaleDateString() +
                                        " " +
                                        new Date(
                                          log.created_at
                                        ).toLocaleTimeString()}
                                    </td>
                                    <td className="text-center">
                                      {log.remoteAddr}
                                    </td>
                                    <td className="text-center">
                                      <span className="badge badge-success">
                                        {log.url}
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Logs;
