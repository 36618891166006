import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { validateEmail, validatePassword } from "../../utils/validator";

import Spinner from "../../components/Spinner";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import the CSS file
import useAuth from '../../context/useAuth';

function Signin() {
  const navigate = useNavigate();
  const { login } = useAuth();


  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [loading, setLoading] = useState(false);
  const [fadeSpinner, setFadeSpinner] = useState(false); // Controls spinner fade

  const onSignInClicked = (e) => {
    e.preventDefault();

    let valid = true;
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email");
      valid = false;
    } else {
      setEmailError("");
    }
    if (!validatePassword(password)) {
      setPasswordError(
        "Please enter a valid password at least 8 characters with upper, lower, speical and number characters"
      );
      valid = false;
    } else {
      setPasswordError("");
    }

    if (valid) {
      setLoading(true);
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/auth/login`, {
          email: email,
          password: password,
        })
        .then((response) => {
          if (response.data.result == true) {
            setLoading(false);
            toast.success("Login successfully!", {
              autoClose: 3000, // Auto close after 3 seconds
            });
            const token = response.data.token;

            login({ name: "Admin", email: email , token : token});
            // Store the token in localStorage (or sessionStorage)
            navigate("/dashboard");
          } else {
            console.log(response);
            setLoading(false);
            toast.error(response.data.err, {
              autoClose: 3000, // Auto close after 3 seconds
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          toast.error("Login failed!", {
            autoClose: 3000, // Auto close after 3 seconds
          });
        });
    }
  };
  return (
    <>
      {loading && <Spinner isVisible={fadeSpinner} />}
      <div className="authincation section-padding">
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-xl-5 col-md-6">
              <div className="mini-logo text-center my-5">
                <Link to={"./"}>
                  <img
                    src={require("../../../images/logo.png")}
                    alt=""
                    height={25}
                  />
                </Link>
              </div>
              <div className="auth-form card">
                <div className="card-header justify-content-center">
                  <h4 className="card-title">Sign in</h4>
                </div>
                <div className="card-body">
                  <form method="post" name="myform" className="signin_validate">
                    <div className="mb-3">
                      <label>Email</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="hello@example.com"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      {emailError && (
                        <p style={{ color: "red" }}>{emailError}</p>
                      )}
                    </div>
                    <div className="mb-3">
                      <label>Password</label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      {passwordError && (
                        <p style={{ color: "red" }}>{passwordError}</p>
                      )}
                    </div>
                    <div className="row d-flex justify-content-between mt-4 mb-2">
                      <div className="mb-3 mb-0">
                        <label className="toggle">
                          <input className="toggle-checkbox" type="checkbox" />
                          <span className="toggle-switch"></span>
                          <span className="toggle-label">Remember me</span>
                        </label>
                      </div>
                      <div className="mb-3 mb-0">
                        <Link to={"./reset"}>Forgot Password?</Link>
                      </div>
                    </div>
                    <div className="text-center">
                      <Link
                        className="btn btn-success btn-block"
                        onClick={onSignInClicked}
                      >
                        Sign in
                      </Link>
                    </div>
                  </form>
                  <div className="new-account mt-3">
                    <p>
                      Don't have an account?{" "}
                      <Link className="text-primary" to={"/signup"}>
                        Sign up
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default Signin;
