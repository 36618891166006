import Pagination from "@mui/material/Pagination";
import { useEffect, useState } from "react";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-rangeslider/lib/index.css";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import axios from "axios";

import Spinner from "jsx/components/Spinner";
import Footer from "jsx/pages/layout/footer";
import Header from "jsx/pages/layout/header";
import Sidebar from "jsx/pages/layout/sidebar";
import NominatorSubmenu from "jsx/pages/layout/submenu_nominator";
import { raoToTao, shortenAddress } from "jsx/utils/validator";
import useAuth from "../../../context/useAuth";

function Nominators() {
  const navigate = useNavigate();

  const { isAuthenticated, user } = useAuth();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/signin");
      return;
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const [fadeSpinner, setFadeSpinner] = useState(false); // Controls spinner fade

  const [totalNominators, setTotalNominators] = useState(0);
  const [nominatorPages, setNominatorPages] = useState(0);
  const [currentNominatorPage, setNominatorPage] = useState(1);
  const [nominators, setNominators] = useState([]);

  // filter states
  const [searchNominatorAddr, setSearchNominatorAddr] = useState("");
  const [searchDelegateAddress, setSearchDelegateAddress] = useState("");
  const [order, setOrder] = useState("balance_desc");
  const [pageSize, setPageSize] = useState("50");

  useEffect(() => {
    clickSearch();
  }, [currentNominatorPage]);

  const changeHotkeyPage = (event, value) => {
    setNominatorPage(value);
  };

  const clickSearch = async () => {
    try {
      setLoading(true);
      const token = user?.token;

      var response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/nominators`,
        {
          nominator_address: searchNominatorAddr,
          delegate_address: searchDelegateAddress,
          order: order,
          pageNum: currentNominatorPage,
          pageSize: pageSize,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      setNominators(response.data.data);
      setTotalNominators(response.data.pagination.total_items);

      if (
        currentNominatorPage > Math.ceil(response.data.pagination.total_pages)
      ) {
        setNominatorPage(1);
      }
      setNominatorPages(response.data.pagination.total_pages);
      setLoading(false);
    } catch (ex) {
      if (ex.response && ex.response.status == 403) {
        navigate("/signin");
        return;
      }
      console.error(ex);
      setLoading(false);
      toast.warn("Network Issue. Please do again in 5 mins.", {
        theme: "dark",
      });
    }
  };

  const copyToClipboard = (textToCopy) => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        toast.success(`Copied the text!`, { theme: "dark" });
      })
      .catch((err) => {
        toast.error(`Failed to copy`, { theme: "dark" });
      });
  };

  return (
    <>
      {loading && <Spinner isVisible={fadeSpinner} />}
      <Header />
      <Sidebar />
      <div className="content-body">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="card sub-menu">
                <div className="card-body">
                  <NominatorSubmenu />
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="row">
                <div className="col-xl-12">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Nominators</h4>
                    </div>
                    <div className="card-body">
                      <div className="transaction-table">
                        <div className="table-responsive">
                          <div className="container-fluid">
                            <div className="row">
                              <div className="col">
                                There are{" "}
                                {
                                  <b style={{ color: "greenyellow" }}>
                                    {totalNominators}
                                  </b>
                                }{" "}
                                nominators
                              </div>
                            </div>
                            <div className="row filter">
                              <div className="col-xl-12">
                                <div className="row">
                                  <div className="mb-3 col-xl-4">
                                    <span className="fs-11">
                                      Nominator Address
                                    </span>
                                    <input
                                      type="string"
                                      className="form-control"
                                      placeholder="Nominator Address"
                                      value={searchNominatorAddr}
                                      onChange={(e) =>
                                        setSearchNominatorAddr(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="mb-3 col-xl-4">
                                    <span className="fs-11">
                                      Delegate Address
                                    </span>

                                    <input
                                      type="string"
                                      className="form-control"
                                      placeholder="Delegate Address"
                                      value={searchDelegateAddress}
                                      onChange={(e) =>
                                        setSearchDelegateAddress(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="mb-3 col-xl-2">
                                    <span className="fs-11">Order</span>
                                    <select
                                      className="form-control select"
                                      name="country"
                                      value={order}
                                      onChange={(e) => setOrder(e.target.value)}
                                    >
                                      <option value="balance_asc">
                                        balance asc
                                      </option>
                                      <option value="balance_desc">
                                        balance desc
                                      </option>
                                      <option value="delegate_from_asc">
                                        block number asc
                                      </option>
                                      <option value="delegate_from_desc">
                                        block number desc
                                      </option>
                                    </select>
                                  </div>
                                  <div className="col-md-2 mb-2">
                                    <span className="fs-11">Page Size</span>
                                    <select
                                      className="form-control"
                                      name="country"
                                      value={pageSize}
                                      onChange={(e) =>
                                        setPageSize(e.target.value)
                                      }
                                    >
                                      <option value="20">PageSize - 20</option>
                                      <option value="50">PageSize - 50</option>
                                      <option value="100">
                                        PageSize - 100
                                      </option>
                                    </select>
                                  </div>
                                  <div className="col-md-auto offset-md-9 text-end mb-3">
                                    <button
                                      className="btn btn-dark waves-effect small"
                                      onClick={clickSearch}
                                    >
                                      <i className="mdi mdi-account-search"></i>
                                      <span> Search</span>
                                    </button>
                                    <button className="btn btn-dark waves-effect">
                                      <i className="mdi mdi-file-export"></i>
                                      <span> Export CSV</span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-2 mb-2">
                              <div className="col-md-12 justify-content-sm-center">
                                <Pagination
                                  count={nominatorPages}
                                  color="primary"
                                  onChange={changeHotkeyPage}
                                />
                              </div>
                            </div>
                          </div>
                          <table className="table table-striped mb-0 table-responsive-sm">
                            <thead>
                              <tr>
                                <th>No</th>
                                <th className="text-center">Nominator</th>
                                <th className="text-center">Delegate</th>
                                <th className="text-center">Staked Balance</th>
                                <th className="text-center">Timestamp</th>
                                <th className="text-center">
                                  Delegate BlockNum
                                </th>
                                <th className="text-center">Update BlockNum</th>
                              </tr>
                            </thead>
                            <tbody>
                              {nominators.map((nominator, index) => {
                                return (
                                  <tr>
                                    <td>
                                      {(Number(currentNominatorPage) - 1) *
                                        Number(pageSize) +
                                        index +
                                        1}
                                    </td>
                                    <td className="text-center">
                                      <Link
                                        to={`/accounts/${nominator.nominator.ss58}`}
                                        className="link-addr"
                                      >
                                        {nominator.nominator.name
                                          ? shortenAddress(
                                              nominator.nominator.name
                                            )
                                          : shortenAddress(
                                              nominator.nominator.ss58
                                            )}
                                      </Link>{" "}
                                      <Link
                                        className="ms-1"
                                        onClick={() => {
                                          copyToClipboard(
                                            nominator.nominator.ss58
                                          );
                                        }}
                                      >
                                        <i className="mdi mdi-content-copy"></i>
                                      </Link>
                                    </td>
                                    <td className="text-center">
                                      {nominator.delegate.name
                                        ? shortenAddress(
                                            nominator.delegate.name
                                          )
                                        : shortenAddress(
                                            nominator.delegate.ss58
                                          )}{" "}
                                      <Link
                                        className="ms-1"
                                        onClick={() => {
                                          copyToClipboard(
                                            nominator.delegate.ss58
                                          );
                                        }}
                                      >
                                        <i className="mdi mdi-content-copy"></i>
                                      </Link>
                                    </td>
                                    <td className="text-center">
                                      {Number(
                                        parseFloat(
                                          raoToTao(nominator.balance)
                                        ).toFixed(3)
                                      ).toLocaleString()}{" "}
                                      <svg
                                        width="1.5em"
                                        height="1.5em"
                                        xmlns="http://www.w3.org/2000/svg"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 3118.1 2721.3"
                                        color="white"
                                        class="text-xl"
                                      >
                                        <title>Bittensor</title>
                                        <path
                                          d="M1808.1,1907.8c-78.4,52.6-159,55.4-240.5,19.1c-76.2-34-111.3-97.6-111.5-179.7c-0.6-236.6-0.2-473.2-0.2-709.9   c0-14.4,0-28.7,0-47.2c-147,0-292.1,0-436.8,0c-9.2-88.9,66.2-185.9,152.2-198.2c36-5.1,72.6-7.9,109-8   c259-0.7,517.9-0.4,776.9-0.4c14.2,0,28.5,0,42.8,0c-1.2,110-78.1,201-202.2,204.2c-126.1,3.3-252.3,0.7-379.9,2.9   c66,27.5,118,69.7,141.7,138c10,28.9,16,60.7,16.3,91.2c1.5,176.1-1,352.3,1.3,528.4C1678.7,1866.8,1722.4,1886,1808.1,1907.8z"
                                          fill="currentColor"
                                        ></path>
                                      </svg>
                                    </td>
                                    <td className="text-center">
                                      {new Date(
                                        nominator.delegate_from_timestamp
                                      ).toLocaleDateString() +
                                        " " +
                                        new Date(
                                          nominator.delegate_from_timestamp
                                        ).toLocaleTimeString()}
                                    </td>
                                    <td className="text-center">
                                      {nominator.delegate_from}
                                    </td>
                                    <td>{nominator.block_number}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </>
  );
}

export default Nominators;
